import { Experiment, NewExperimentRequest, Variable } from "../types/types";
import { getIdToken } from "../utils/Utils";

const apiUrl: string = process.env.REACT_APP_PLATFORM_API ?? "API URL NOT SET";
const ApiKey: string =
  process.env.REACT_APP_PLATFORM_API_KEY ?? "API KEY NOT SET";

const ACCESS_DENIED = new Error("Access denied");
const NON_2XX_ERROR = new Error("Non 2XX error");
const NOT_FOUND = new Error("Not found");

const UpdateVariable = async (variable: Variable, experimentID: string) => {
  const response = await fetch(
    `${apiUrl}/experiment/${experimentID}/variables`,
    {
      method: "post",
      headers: {
        "x-api-key": ApiKey,
        Authorization: "Bearer " + (await getIdToken()),
      },
      body: JSON.stringify(variable),
    },
  ).then((data) => {
    if (data.status === 200) {
      return;
    } else if (data.status === 403) {
      throw ACCESS_DENIED;
    } else {
      throw NON_2XX_ERROR;
    }
  });
  return response;
};

const AddDataPoint = async (
  variable: Record<string, string>,
  experimentID: string,
) => {
  const response = await fetch(
    `${apiUrl}/experiment/${experimentID}/datapoint`,
    {
      method: "post",
      headers: {
        "x-api-key": ApiKey,
        Authorization: "Bearer " + (await getIdToken()),
      },
      body: JSON.stringify(variable),
    },
  ).then((data) => {
    if (data.status === 200) {
      return;
    } else if (data.status === 403) {
      throw ACCESS_DENIED;
    } else {
      throw NON_2XX_ERROR;
    }
  });
  return response;
};

const AddVariable = async (variable: Variable, experimentID: string) => {
  const response = await fetch(
    `${apiUrl}/experiment/${experimentID}/variables`,
    {
      method: "put",
      headers: {
        "x-api-key": ApiKey,
        Authorization: "Bearer " + (await getIdToken()),
      },
      body: JSON.stringify(variable),
    },
  ).then((data) => {
    if (data.status === 200) {
      return;
    } else if (data.status === 403) {
      throw ACCESS_DENIED;
    } else {
      throw NON_2XX_ERROR;
    }
  });
  return response;
};

const DeleteExperiment = async (experimentID: string) => {
  const response = await fetch(apiUrl + "/experiment/" + experimentID, {
    method: "delete",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + (await getIdToken()),
    },
  }).then((data) => {
    if (data.status === 204) {
      return;
    } else if (data.status === 403) {
      throw ACCESS_DENIED;
    } else {
      throw NON_2XX_ERROR;
    }
  });
  return response;
};

const GetValidateUser = async (username: string) => {
  const response = await fetch(apiUrl + "/verify/" + username, {
    method: "get",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + (await getIdToken()),
    },
  }).then((data) => {
    if (data.status === 200) {
      return data.json();
    } else if (data.status === 404) {
      throw NOT_FOUND;
    } else {
      throw NON_2XX_ERROR;
    }
  });
  return response;
};

const GetExperiment = async (experimentID: string) => {
  const response = await fetch(apiUrl + "/experiment/" + experimentID, {
    method: "get",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + (await getIdToken()),
    },
  }).then((data) => {
    if (data.status === 200) {
      return data.json();
    } else if (data.status === 403) {
      throw ACCESS_DENIED;
    } else {
      throw NON_2XX_ERROR;
    }
  });
  return response as unknown as Experiment;
};

const CreateExperiment = async (experiment: NewExperimentRequest) => {
  const response = await fetch(apiUrl + "/experiment", {
    method: "POST",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + (await getIdToken()),
    },
    body: JSON.stringify(experiment),
  }).then((data) => {
    if (data.status === 200) {
      return "";
    } else {
      throw NON_2XX_ERROR;
    }
  });
  return response;
};

const GetAccount = async () => {
  const response = await fetch(apiUrl + "/account", {
    method: "get",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + (await getIdToken()),
    },
  }).then((data) => {
    if (data.status === 200) {
      return "";
    } else {
      throw NON_2XX_ERROR;
    }
  });
  return response;
};

const GetUserExperiments = async () => {
  const response = await fetch(apiUrl + "/experiment", {
    method: "get",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + (await getIdToken()),
    },
  }).then((data) => {
    if (data.status === 200) {
      return data.json();
    } else {
      throw NON_2XX_ERROR;
    }
  });
  return response as unknown as Experiment[];
};

export {
  AddVariable,
  CreateExperiment,
  GetAccount,
  UpdateVariable,
  GetUserExperiments,
  GetValidateUser,
  GetExperiment,
  DeleteExperiment,
  AddDataPoint,
  ACCESS_DENIED,
  NON_2XX_ERROR,
};

import { useEffect, useState } from "react";
import { Experiment } from "../../../types/types";
import { AddDataPoint, GetExperiment } from "../../../api/securePlatformApi";

interface useExperimentProps {
  experimentID: string | undefined;
}

const useExperiment = ({ experimentID }: useExperimentProps) => {
  const [experiment, setExperiment] = useState<Experiment>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [experimentError, setExperimentError] = useState<Error>();

  // use effect runs on load
  useEffect(() => {
    if (experimentID) {
      getExperiment(experimentID);
    }
  }, [experimentID]);

  const getExperiment = async (experimentID: string) => {
    setIsLoading(true);
    try {
      const experiment = await GetExperiment(experimentID);
      setExperiment(experiment);
    } catch (e) {
      if (e instanceof Error) {
        setExperimentError(e);
      }
    }

    setIsLoading(false);
  };

  const addDataPoint = async (
    experimentID: string,
    variable: Record<string, string>,
  ) => {
    setIsLoading(true);
    try {
      await AddDataPoint(variable, experimentID);
    } catch (e) {
      if (e instanceof Error) {
        console.log(e); //TODO handle this
      }
    }
    getExperiment(experimentID);
    setIsLoading(false);
  };

  return {
    getExperiment,
    addDataPoint,
    experimentError,
    experiment,
    isLoading,
  };
};

export default useExperiment;

const CustomAnalysis = () => {
  return (
    <div className="p-40 justify-items-center">
      <div className="text-lg font-semibold">FEATURE COMING SOON</div>
      <div className="text-sm font-light">
        Soon you&apos;ll be able to write your own analysis either by connecting
        code hosted on github, supplying an API endpoint, or writing the code
        directly into our editor.
      </div>
    </div>
  );
};

export default CustomAnalysis;

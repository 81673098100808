import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import useExperiments from "../../experimentsPage/hooks/useExperiments";
import { Select, SelectOption } from "../../../components/Select/Select";
import { useEffect, useState } from "react";

interface AddVariableDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const HistogramDrawer = ({ onClose, isOpen }: AddVariableDrawerProps) => {
  const { experiments, isLoading } = useExperiments();
  const [experimentOptions, setExperimentOptions] = useState<SelectOption[]>(
    [],
  );

  console.log(isLoading);
  console.log(experiments);

  useEffect(() => {
    const newExperimentOptions: SelectOption[] = [];
    experiments.forEach((experiment) => {
      newExperimentOptions.push({
        id: experiment.id,
        value: experiment.name,
      });
    });
    setExperimentOptions(newExperimentOptions);
  }, [experiments]);

  return (
    <Drawer size={"lg"} placement={"right"} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader className="font-prosto" borderBottomWidth="1px">
          Attach a histogram analysis
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody className="mt-4 font-poppins">
          <FormControl>
            <FormLabel>Select an experiment</FormLabel>
            <Select
              setValue={() => {}}
              options={experimentOptions}
              value={experimentOptions[0]}
            />
          </FormControl>
        </DrawerBody>
        <DrawerFooter borderTopWidth="1px">
          <Button
            className="mr-2"
            colorScheme="red"
            variant="outline"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button colorScheme="red" variant="solid">
            Attach
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default HistogramDrawer;

import { SmallAddIcon } from "@chakra-ui/icons";
import {
  Button,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Filter, X } from "react-feather";
import { Experiment } from "../../../types/types";

interface TableFilterProps {
  isLoading: boolean;
  experiments: Experiment[];
  setFilteredExperiments: (filteredExperiments: Experiment[]) => void;
}

interface UserFilter {
  fieldName: string;
  fieldNameMapping: string;
  fieldValue?: string;
}

const TableFilter = ({
  isLoading,
  experiments,
  setFilteredExperiments,
}: TableFilterProps) => {
  const [filters, setFilters] = useState<UserFilter[]>([]);

  useEffect(() => {
    const filteredExperiments: Experiment[] = [];
    experiments.forEach((experiment) => {
      let include = true;
      filters.forEach((filter) => {
        const experimentValue =
          experiment[filter.fieldNameMapping as keyof Experiment];
        if (
          filter.fieldValue &&
          typeof experimentValue === "string" &&
          !experimentValue.includes(filter.fieldValue)
        ) {
          include = false;
          return;
        }

        if (
          filter.fieldValue &&
          typeof experimentValue === "number" &&
          !(experimentValue + "").includes(filter.fieldValue)
        ) {
          include = false;
          return;
        }
      });

      if (include) {
        filteredExperiments.push(experiment);
      }
    });
    setFilteredExperiments(filteredExperiments);
  }, [filters, experiments, setFilteredExperiments]);

  return (
    <div className="flex flex-row flex-wrap gap-2">
      {filters.map((filter, index) => {
        return (
          <InputGroup key={filter.fieldName + index} size={"sm"} width={"fit"}>
            <InputLeftAddon rounded={"md"}>{filter.fieldName}</InputLeftAddon>
            <Input
              value={filter.fieldValue}
              rounded={"md"}
              width={"fit"}
              onChange={(e) => {
                const filtersCopy = structuredClone(filters);
                filtersCopy[index].fieldValue = e.target.value;
                setFilters(filtersCopy);
              }}
            />
            <InputRightAddon
              rounded={"md"}
              backgroundColor={"white"}
              cursor={"pointer"}
              onClick={() => {
                const filtersCopy = structuredClone(filters);
                filtersCopy.splice(index, 1);
                setFilters(filtersCopy);
              }}
            >
              <X size={"14"} />
            </InputRightAddon>
          </InputGroup>
        );
      })}

      <Skeleton rounded={"md"} fitContent={true} isLoaded={!isLoading}>
        <Menu>
          {({ isOpen }) => (
            <>
              {filters.length === 0 && (
                <MenuButton
                  size={"sm"}
                  variant={"outline"}
                  isActive={isOpen}
                  as={Button}
                  rightIcon={<Filter size={"14"} />}
                >
                  Add Filter
                </MenuButton>
              )}
              {filters.length > 0 && (
                <MenuButton
                  size={"sm"}
                  variant={"outline"}
                  isActive={isOpen}
                  as={IconButton}
                  icon={<SmallAddIcon />}
                >
                  Add Filter
                </MenuButton>
              )}
              <MenuList>
                <MenuItem
                  onClick={() => {
                    const filtersCopy = structuredClone(filters);
                    filtersCopy.push({
                      fieldName: "Experiment ID",
                      fieldNameMapping: "id",
                    });
                    setFilters(filtersCopy);
                  }}
                >
                  Experiment ID
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    const filtersCopy = structuredClone(filters);
                    filtersCopy.push({
                      fieldName: "Experiment Name",
                      fieldNameMapping: "name",
                    });
                    setFilters(filtersCopy);
                  }}
                >
                  Experiment Name
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    const filtersCopy = structuredClone(filters);
                    filtersCopy.push({
                      fieldName: "Created By",
                      fieldNameMapping: "createdBy",
                    });
                    setFilters(filtersCopy);
                  }}
                >
                  Created By
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Skeleton>
    </div>
  );
};

export default TableFilter;

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Progress,
} from "@chakra-ui/react";
import NavLink from "./NavLink";
import { Home, Codepen, Database, BarChart2 } from "react-feather";
import { ReactElement, useEffect, useState } from "react";
import { getFullName, logOut } from "../../../utils/Utils";

interface SideNavigationBarProps {
  isOpen: boolean;
  onClose: () => void;
}

const SideNavigationLinks = (fullName: string): ReactElement => (
  <>
    <div className="flex flex-col h-[100%]">
      <div>
        <NavLink
          selected={window.location.pathname === "/dashboard/experiments"}
          href={"/dashboard/experiments"}
          icon={<Home size={18} />}
        >
          Experiments
        </NavLink>
        <NavLink
          selected={window.location.pathname === "/dashboard/analysis"}
          href={"/dashboard/analysis"}
          icon={<Codepen size={18} />}
        >
          Analysis
        </NavLink>
        <NavLink
          selected={window.location.pathname === "/dashboard/experiment-data"}
          href={"/dashboard/experiment-data"}
          icon={<Database size={18} />}
        >
          Experiment Data
        </NavLink>
        <NavLink
          selected={window.location.pathname === "/dashboard/analysis-results"}
          href={"/dashboard/analysis-results"}
          icon={<BarChart2 size={18} />}
        >
          Analysis Results
        </NavLink>
      </div>
      <div className="grow"></div>
      <div className="border-t-[1px] border-[#00000055] mb-5">
        <div className="font-poppins text-xs mt-4">Remaining API Allowance</div>
        <div className="font-poppins text-lg font-bold mb-4">0</div>
        <Progress backgroundColor={"#00000010"} colorScheme="red" value={0} />
        <Button
          className="mt-5 lg:mb-[0px]"
          as={"a"}
          size={"sm"}
          href="/dashboard/account-settings"
          width={"-webkit-fill-available"}
          colorScheme="purple"
          bgGradient="linear(to-l, #7928CA, #FF0080)"
        >
          Upgrade Account
        </Button>
      </div>
      <div className="border-t-[1px] border-[#00000055]">
        <div className="font-poppins text-xs mt-4">Logged in as</div>
        <div className="font-poppins text-sm font-bold mb-4">{fullName}</div>
        <Button
          className="mb-2"
          variant={"outline"}
          width={"-webkit-fill-available"}
          colorScheme="red"
          size={"sm"}
          onClick={() => {
            logOut().then(() => {
              document.location.href = "/";
            });
          }}
        >
          Sign out
        </Button>
        <Button
          className="mb-2 lg:mb-[0px]"
          as={"a"}
          href="/dashboard/account-settings"
          width={"-webkit-fill-available"}
          size={"sm"}
          colorScheme="red"
        >
          Account Settings
        </Button>
      </div>
    </div>
  </>
);

const SideNavigationBar = ({ isOpen, onClose }: SideNavigationBarProps) => {
  const [fullName, setFullName] = useState<string>();

  useEffect(() => {
    getFullName().then((name) => {
      setFullName(name);
    });
  }, [fullName]);

  return (
    <>
      {/* Desktop Side Nav */}
      <div className="p-7 h-[100vh] min-w-72 w-72 sticky top-0 hidden lg:block bg-beige">
        {SideNavigationLinks(fullName ?? "")}
      </div>

      <Drawer placement={"left"} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader className="bg-beige">
            <DrawerCloseButton />
          </DrawerHeader>
          <DrawerBody className="bg-beige">
            {SideNavigationLinks(fullName ?? "")}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SideNavigationBar;

import { ReactNode, useEffect, useState } from "react";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export interface SelectOption {
  id: number | string;
  value: string;
  image?: ReactNode;
}

interface SelectProps {
  options: SelectOption[];
  setValue: (value: SelectOption) => void;
  value: SelectOption;
}

export function Select({ options, setValue, value }: SelectProps) {
  const [selected, setSelected] = useState(options[0]);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <Listbox
      value={selected}
      onChange={(e) => {
        setSelected(e);
        setValue(e);
      }}
    >
      <div className="relative mt-2">
        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm/6">
          <span className="flex items-center">
            {selected.image && <>{selected.image}</>}
            <span className="ml-3 block truncate">{selected.value}</span>
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
            <ChevronUpDownIcon
              aria-hidden="true"
              className="h-5 w-5 text-gray-400"
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
        >
          {options.map((option) => (
            <ListboxOption
              key={option.id}
              value={option}
              className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-teal-600 data-[focus]:text-white"
            >
              <div className="flex items-center">
                {option.image && <>{option.image}</>}

                <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                  {option.value}
                </span>
              </div>

              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                <CheckIcon aria-hidden="true" className="h-5 w-5" />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}

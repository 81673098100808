import {
  Alert,
  AlertIcon,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Skeleton,
} from "@chakra-ui/react";
import { DataTableOverview } from "../../../types/types";
import DataTable from "../../../components/dataTable/DataTable";
import { Book } from "react-feather";

interface ExperimentDataOverviewProps {
  dataTableOverview?: DataTableOverview;
  isLoading: boolean;
}

const ExperimentDataOverview = ({
  dataTableOverview,
  isLoading,
}: ExperimentDataOverviewProps) => {
  const shouldShowLatestDataTable = (): boolean => {
    if (isLoading) {
      return true;
    }
    if (dataTableOverview?.latestRecords?.length === 0) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div className="flex flex-row gap-4">
        <Card className="basis-full">
          <CardBody>
            <h3 className="font-poppins text-sm font-bold">Approx records</h3>
            <Skeleton isLoaded={!isLoading}>
              <p className="font-poppins text-2xl font-light mt-1">
                {dataTableOverview?.itemCount ?? "0"}
              </p>
            </Skeleton>
          </CardBody>
        </Card>
        <Card className="basis-full">
          <CardBody>
            <h3 className="font-poppins text-sm font-bold">Database size</h3>
            <Skeleton isLoaded={!isLoading}>
              <p className="font-poppins text-2xl font-light mt-1">
                {dataTableOverview?.tableSize
                  ? dataTableOverview?.tableSize
                  : 0}{" "}
                MiB
              </p>
            </Skeleton>
          </CardBody>
        </Card>
        <Card className="basis-full">
          <CardBody>
            <h3 className="font-poppins text-sm font-bold">Database status</h3>
            <Skeleton isLoaded={!isLoading}>
              <Alert
                as={"p"}
                className="font-poppins text-2xl font-light mt-1"
                padding={0}
                backgroundColor={"white"}
                status={
                  dataTableOverview?.tableStatus === "Available"
                    ? "success"
                    : "error"
                }
              >
                <AlertIcon />
                {dataTableOverview?.tableStatus}
              </Alert>
            </Skeleton>
          </CardBody>
        </Card>
      </div>
      <Card marginTop={5}>
        {shouldShowLatestDataTable() && (
          <CardHeader paddingBottom={0}>
            <h3 className="font-poppins text-md font-bold">Latest Data</h3>
          </CardHeader>
        )}
        <CardBody paddingTop={2} paddingBottom={5}>
          <DataTable
            isLoading={isLoading}
            data={dataTableOverview?.latestRecords}
          ></DataTable>
        </CardBody>
        {shouldShowLatestDataTable() && (
          <CardFooter paddingTop={0}>
            <Button
              as={"a"}
              href="/"
              variant={"outline"}
              size={"sm"}
              rightIcon={<Book size={16} />}
            >
              See full results
            </Button>
          </CardFooter>
        )}
      </Card>
    </>
  );
};

export default ExperimentDataOverview;

import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import NewExperimentDrawer from "./components/NewExperimentDrawer";
import useExperiments from "./hooks/useExperiments";
import ExperimentsTable from "./components/ExperimentsTable";
import { GetAccount } from "../../api/securePlatformApi";

const ExperimentsPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { experiments, isLoading, createExperiment, deleteExperiments } =
    useExperiments();

  //Needs to be called
  GetAccount();

  return (
    <>
      <div className="flex">
        <h1 className="font-poppins text-3xl font-semibold">Experiments</h1>
        <div className="grow"></div>
        <Button
          colorScheme={"red"}
          size={"sm"}
          rightIcon={<AddIcon />}
          onClick={onOpen}
        >
          Create
        </Button>
      </div>

      <Tabs colorScheme={"red"}>
        <TabList>
          <Tab>Live Experiments</Tab>
          <Tab>Frozen Experiments</Tab>
        </TabList>

        <TabPanels>
          <TabPanel padding={"0"} paddingTop={5}>
            <ExperimentsTable
              isLoading={isLoading}
              experiments={experiments}
              deleteExperiments={deleteExperiments}
            />
          </TabPanel>
          <TabPanel padding={"0"}>
            <ExperimentsTable
              isLoading={isLoading}
              experiments={[]}
              deleteExperiments={deleteExperiments}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <NewExperimentDrawer
        isLoading={isLoading}
        onClose={onClose}
        isOpen={isOpen}
        onCreate={createExperiment}
      />
    </>
  );
};

export default ExperimentsPage;

import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import CustomAnalysis from "./components/CustomAnalysis";
import ManagedAnalysis from "./components/ManagedAnalysis";

const AnalysisPage = () => {
  return (
    <>
      <div className="flex">
        <h1 className="font-poppins text-3xl font-semibold">Analysis</h1>
      </div>
      <Tabs colorScheme={"red"}>
        <TabList>
          <Tab>Managed Analysis</Tab>
          <Tab>Custom Analysis</Tab>
        </TabList>

        <TabPanels>
          <TabPanel padding={"0"} paddingTop={8} paddingBottom={16}>
            <ManagedAnalysis />
          </TabPanel>
          <TabPanel padding={"0"} paddingTop={5}>
            <CustomAnalysis />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default AnalysisPage;

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { useState } from "react";
import { CloseIcon } from "@chakra-ui/icons";
import { NewExperimentRequest } from "../../../types/types";
import Collaborators from "./Collaborators";

interface NewExperimentDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (experiment: NewExperimentRequest) => Promise<void>;
  isLoading: boolean;
}

const NewExperimentDrawer = ({
  onClose,
  isOpen,
  onCreate,
  isLoading,
}: NewExperimentDrawerProps) => {
  const [collaborators, setCollaborators] = useState<string[]>([]);
  const [shouldValidate, setShouldValidate] = useState<boolean>(false);
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();

  return (
    <Drawer size={"lg"} placement={"right"} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader className="font-prosto" borderBottomWidth="1px">
          Create an experiment
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody className="mt-4">
          <FormControl
            fontFamily={"poppins"}
            isInvalid={(!!name === false && shouldValidate) ?? name !== ""}
          >
            <FormLabel>Experiment Name</FormLabel>
            <Input
              onChange={(event) => {
                setName(event.target.value);
              }}
              variant={"outline"}
            />
            <FormErrorMessage>Experiment name cannot be empty</FormErrorMessage>
          </FormControl>

          <FormControl
            fontFamily={"poppins"}
            isInvalid={
              (!!description === false && shouldValidate) ?? description !== ""
            }
          >
            <FormLabel className="mt-5">Experiment Description</FormLabel>
            <Textarea
              variant={"outline"}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
            <FormErrorMessage>
              Experiment description cannot be empty
            </FormErrorMessage>
          </FormControl>

          <Collaborators
            setCollaborators={setCollaborators}
            collaborators={collaborators}
          />

          <div className="mt-4">
            {collaborators.map((collaborator, index) => {
              return (
                <div
                  key={index}
                  className="bg-[#00000011] my-2 rounded-xl px-3 py-1 text-sm font-semibold w-fit"
                >
                  {collaborator}
                  <Tooltip rounded={"xl"} label="remove collaborator">
                    <IconButton
                      size={"xs"}
                      rounded={"3xl"}
                      marginLeft={"2"}
                      colorScheme="gray"
                      aria-label="remove collaborator"
                      icon={<CloseIcon />}
                      onClick={() => {
                        const collaboratorsCopy =
                          structuredClone(collaborators);
                        collaboratorsCopy.splice(index, 1);
                        setCollaborators(collaboratorsCopy);
                      }}
                    />
                  </Tooltip>
                </div>
              );
            })}
          </div>
        </DrawerBody>
        <DrawerFooter borderTopWidth="1px">
          <Button
            className="mr-2"
            colorScheme="red"
            variant="outline"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            colorScheme="red"
            variant="solid"
            onClick={async () => {
              setShouldValidate(true);

              if (!name) {
                return;
              }

              if (!description) {
                return;
              }

              const experiment: NewExperimentRequest = {
                name: name,
                description: description,
                collaborators: collaborators,
              };
              await onCreate(experiment);
              onClose();
            }}
          >
            Create Experiment
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default NewExperimentDrawer;

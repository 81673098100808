import { Button, Grid, useDisclosure } from "@chakra-ui/react";
import { Experiment } from "../../../types/types";
import EditableVariableTile from "./EditableVariableTile";
import { AddIcon, SmallAddIcon } from "@chakra-ui/icons";
import AddVariableDrawer from "./AddVariableDrawer";

interface VariableDefinitionProps {
  experiment: Experiment | undefined;
  updateExperiments: () => void;
}

const VariableDefinition = ({
  experiment,
  updateExperiments,
}: VariableDefinitionProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (experiment?.variables === null) {
    return (
      <div className="m-5 p-40 justify-items-center">
        <div className="text-lg font-semibold">No Variables</div>
        <div className="text-sm font-light">
          You need to define your experiment variables to get started
        </div>
        <Button
          marginTop={5}
          variant={"outline"}
          size={"md"}
          colorScheme="red"
          marginBottom={5}
          rightIcon={<AddIcon />}
          onClick={onOpen}
        >
          Add Variable
        </Button>
        <AddVariableDrawer
          experimentID={experiment?.id ?? ""}
          onClose={onClose}
          isOpen={isOpen}
          updateExperiments={updateExperiments}
        />
      </div>
    );
  }

  return (
    <>
      <Button
        variant={"outline"}
        size={"sm"}
        marginBottom={5}
        rightIcon={<SmallAddIcon />}
        onClick={onOpen}
      >
        Add Variable
      </Button>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        {experiment?.variables?.map((variable, index) => {
          return (
            <EditableVariableTile
              updateExperiments={updateExperiments}
              experimentId={experiment.id ?? ""}
              key={index}
              variable={variable}
              tagCopy="undefined variable type"
              isProtected={true}
              tagColor="red"
            />
          );
        })}
      </Grid>
      <AddVariableDrawer
        experimentID={experiment?.id ?? ""}
        onClose={onClose}
        isOpen={isOpen}
        updateExperiments={updateExperiments}
      />
    </>
  );
};

export default VariableDefinition;

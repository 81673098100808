import { useDisclosure } from "@chakra-ui/react";
import Content from "./components/Content";
import SideNavigationBar from "./components/SideNavigationBar";
import { Outlet } from "react-router-dom";

const DashboardLayout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div className="w-full min-w-[800px] min-h-[100vh]">
      <div className="flex">
        <SideNavigationBar isOpen={isOpen} onClose={onClose} />
        <Content openMobileDrawer={onOpen}>
          <Outlet />
        </Content>
      </div>
    </div>
  );
};

export default DashboardLayout;

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
} from "@chakra-ui/react";
import { Experiment } from "../../../types/types";
import { useState } from "react";

interface AddRecordDrawerProps {
  experiment: Experiment | undefined;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  addDataPoint: (
    experimentID: string,
    variable: Record<string, string>,
  ) => Promise<void>;
}

const AddRecordDrawer = ({
  onClose,
  isOpen,
  experiment,
  isLoading,
  addDataPoint,
  // addDataPoint,
}: AddRecordDrawerProps) => {
  const [variables, setVariables] = useState<Record<string, string>>({});
  const [shouldValidate, setShouldValidate] = useState<boolean>(false);

  return (
    <Drawer size={"lg"} placement={"right"} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader className="font-prosto" borderBottomWidth="1px">
          Add a datapoint
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          {experiment?.variables === null && (
            <p>You need to define your variables first.</p>
          )}
          {experiment?.variables?.map((variable, index) => {
            return (
              <FormControl
                key={index}
                className="mt-4"
                isInvalid={
                  shouldValidate &&
                  (!!variables[variable.name] === false ||
                    variables[variable.name].length > 0)
                }
              >
                <FormLabel>{variable.name}</FormLabel>
                <InputGroup>
                  <Input
                    placeholder={variable.type}
                    onChange={(e) => {
                      const newVariables = structuredClone(variables);
                      newVariables[variable.name] = e.target.value;
                      setVariables(newVariables);
                    }}
                  ></Input>
                  {variable.unit && (
                    <InputRightAddon>{variable.unit}</InputRightAddon>
                  )}
                </InputGroup>
                {variable.description && (
                  <FormHelperText>{variable.description}</FormHelperText>
                )}
              </FormControl>
            );
          })}
        </DrawerBody>
        <DrawerFooter borderTopWidth="1px">
          <Button
            className="mr-2"
            colorScheme="red"
            variant="outline"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            colorScheme="red"
            variant="solid"
            onClick={() => {
              setShouldValidate(true);

              if (!experiment?.id) {
                return;
              }

              if (!experiment?.variables) {
                return;
              }

              for (let i = 0; i < experiment.variables.length; i++) {
                if (variables[experiment?.variables[i].name] === undefined) {
                  return;
                }
              }

              addDataPoint(experiment.id ?? "", variables);
              onClose();
            }}
            disabled={experiment?.variables === null}
          >
            Add Datapoint
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default AddRecordDrawer;

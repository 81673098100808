import { Button, ButtonGroup, Tooltip } from "@chakra-ui/react";
import { Archive, Trash } from "react-feather";

interface ExperimentsTableProps {
  tooltip: string | undefined;
  isDisabled: boolean;
  onDelete: () => void;
  isDeleteLoading: boolean;
}

const OwnerControlButtons = ({
  onDelete,
  isDisabled,
  tooltip,
  isDeleteLoading,
}: ExperimentsTableProps) => {
  const ButtonGroupComponent = (
    <ButtonGroup size="sm" isAttached variant="outline">
      <Button
        disabled={isDisabled}
        rightIcon={<Trash size={"14"} />}
        onClick={onDelete}
        isLoading={isDeleteLoading}
      >
        Delete
      </Button>
      <Button disabled={isDisabled} rightIcon={<Archive size={"14"} />}>
        Freeze
      </Button>
    </ButtonGroup>
  );

  if (tooltip) {
    return (
      <>
        <Tooltip label={tooltip} shouldWrapChildren={false}>
          {ButtonGroupComponent}
        </Tooltip>
      </>
    );
  }

  return ButtonGroupComponent;
};

export default OwnerControlButtons;

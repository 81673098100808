import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { Variable } from "../../../types/types";
import EditVariableDrawer from "./EditVariableDrawer";

interface VariableDefinitionProps {
  experimentId: string;
  variable: Variable | undefined;
  tagCopy: string;
  tagColor: string;
  isProtected: boolean;
  updateExperiments: () => void;
}

const EditableVariableTile = ({
  variable,
  tagCopy,
  isProtected,
  experimentId,
  updateExperiments,
}: VariableDefinitionProps) => {
  const toCamelcase = (word: string) => {
    let responseString = "";
    responseString = word[0];
    responseString = responseString + word.slice(1).toLowerCase();
    return responseString;
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Card>
        <CardBody>
          <Badge>{tagCopy}</Badge>
          <h3 className="font-poppins text-xs font-semibold mt-3">Name</h3>
          <p className="font-poppins text-xl font-light">{variable?.name}</p>
          <h3 className="font-poppins text-xs font-semibold mt-3">Type</h3>
          <p className="font-poppins text-xl font-light">
            {toCamelcase(variable?.type ?? "")}
          </p>
          <h3 className="font-poppins text-xs font-semibold mt-3">Unit</h3>
          <p className="font-poppins text-xl font-light">
            {variable?.unit ?? "-"}
          </p>
          <h3 className="font-poppins text-xs font-semibold mt-3">
            Description
          </h3>
          <p className="font-poppins text-sm font-light mt-1">
            {variable?.description ?? "-"}
          </p>
        </CardBody>
        <Divider color={"#00000035"} />
        <CardFooter justifyContent={"right"}>
          <ButtonGroup spacing="2">
            <Tooltip label={"Feature coming soon!"}>
              <Button
                variant="outline"
                colorScheme="red"
                disabled={isProtected}
              >
                Delete
              </Button>
            </Tooltip>
            <Button variant="solid" colorScheme="red" onClick={onOpen}>
              Edit
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
      {variable && (
        <EditVariableDrawer
          updateExperiments={updateExperiments}
          experimentID={experimentId}
          variable={variable}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default EditableVariableTile;

enum ExperimentState {
  LIVE = "LIVE",
  ARCHIVED = "ARCHIVED",
}

enum VariableType {
  STRING = "String",
  NUMBER = "Number",
  STRING_ARRAY = "String Array",
  NUMBER_ARRAY = "Number Array",
}

interface Variable {
  id?: string;
  name: string;
  type: VariableType;
  description?: string;
  unit?: string;
}

interface DataTableOverview {
  itemCount: number;
  tableStatus: string;
  tableSize: number; //in bytes
  latestRecords: Record<string, unknown>[];
}

interface NewExperimentRequest {
  name: string;
  description: string;
  collaborators?: string[];
}

interface Experiment {
  id: string;
  name: string;
  description: string;
  state: ExperimentState;
  createdBy: string;
  collaborators: string[];
  variables?: Variable[];
  dataTableOverview: DataTableOverview;
}

export { ExperimentState, VariableType };
export type { Experiment, Variable, DataTableOverview, NewExperimentRequest };

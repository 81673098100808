import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { Variable, VariableType } from "../../../types/types";
import useVariables from "../hooks/useVariables";
import { useState } from "react";
import { Select } from "../../../components/Select/Select";
import { variableOptions } from "../../../types/selectOptions";

interface AddVariableDrawerProps {
  experimentID: string;
  isOpen: boolean;
  onClose: () => void;
  updateExperiments: () => void;
}

const AddVariableDrawer = ({
  onClose,
  isOpen,
  experimentID,
  updateExperiments,
}: AddVariableDrawerProps) => {
  const { addVariable, error, isLoading } = useVariables();
  const [variable, setVariable] = useState<Variable>({
    name: "",
    type: variableOptions[0].id as VariableType,
  });

  const [validate, setValidate] = useState<boolean>(false);

  return (
    <Drawer size={"lg"} placement={"right"} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader className="font-prosto" borderBottomWidth="1px">
          Add Variable
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody className="mt-4 font-poppins">
          <FormControl isInvalid={validate && variable.name === ""}>
            <FormLabel>Name</FormLabel>
            <Input
              onChange={(e) => {
                setVariable({
                  ...variable,
                  name: e.target.value,
                });
              }}
            />
            {!(validate && variable.name === "") && (
              <FormHelperText>
                You cannot edit the variable name once created. If you need to -
                you need to delete the variable and re-create it. However
                deleting a variable will result in data loss.
              </FormHelperText>
            )}
            <FormErrorMessage>
              You must provide a variable name.
            </FormErrorMessage>
          </FormControl>
          <FormControl marginTop={4}>
            <FormLabel>Type</FormLabel>
            <Select
              options={variableOptions}
              value={variableOptions[0]}
              setValue={(option) => {
                setVariable({
                  ...variable,
                  type: option.id as VariableType,
                });
              }}
            />
            <FormHelperText>
              You cannot edit the variable type once created. If you need to -
              you need to delete the variable and re-create it. However deleting
              a variable will result in data loss.
            </FormHelperText>
          </FormControl>
          <FormControl marginTop={4}>
            <FormLabel>Unit (Optional)</FormLabel>
            <Input
              onChange={(e) => {
                setVariable({
                  ...variable,
                  unit: e.target.value,
                });
              }}
            />
            <FormHelperText>
              Help other collaborators by defining the unit the data should be
              uploaded as.
            </FormHelperText>
          </FormControl>
          <FormControl marginTop={4}>
            <FormLabel>Description (Optional)</FormLabel>
            <Textarea
              onChange={(e) => {
                setVariable({
                  ...variable,
                  description: e.target.value,
                });
              }}
            />
            <FormHelperText>
              Help other collaborators by proving a description of this
              variable.
            </FormHelperText>
          </FormControl>
        </DrawerBody>
        <DrawerFooter borderTopWidth="1px">
          <Button
            className="mr-2"
            colorScheme="red"
            variant="outline"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            colorScheme="red"
            variant="solid"
            onClick={async () => {
              setValidate(true);
              if (variable.name === "") {
                return;
              }
              if (variable) {
                await addVariable(variable, experimentID);
              }
              if (error) {
                return;
              }
              await updateExperiments();
              onClose();
            }}
            isLoading={isLoading}
          >
            Add Variable
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default AddVariableDrawer;

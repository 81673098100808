import { Box, Button, Input } from "@chakra-ui/react";
import { Dispatch, ReactElement, SetStateAction, useState } from "react";

interface InputWithButtonProps {
  icon: ReactElement;
  placeholder: string;
  buttonText: string;
  className?: string;
  onSubmit?: (
    value: string,
    setValue: Dispatch<SetStateAction<string>>,
  ) => void;
  onChange?: () => void;
  isLoading: boolean;
}

const InputWithButton = ({
  icon,
  placeholder,
  className,
  buttonText,
  onSubmit,
  onChange,
  isLoading,
}: InputWithButtonProps) => {
  const [value, setValue] = useState<string>("");

  return (
    <Box
      className={className}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Input
        value={value}
        placeholder={placeholder}
        paddingTop={"0px"}
        roundedRight={"none"}
        borderRight={"none"}
        onChange={(e) => {
          setValue(e.target.value);
          if (onChange) {
            onChange();
          }
        }}
      />
      <Button
        roundedLeft={"none"}
        colorScheme="red"
        rightIcon={icon}
        margin={0}
        isLoading={isLoading}
        onClick={() => {
          if (onSubmit) {
            onSubmit(value, setValue);
          }
        }}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

export default InputWithButton;
